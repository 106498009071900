<template>
	<div v-if="props.page.slug === '404'">
		<heading-image
			v-if="props.page.headingImage"
			:heading-image="{
				image: props.page.headingImage,
				imageWebp: props.page.headingImageWebp,
				imagePortrait: props.page.headingImagePortrait,
				imagePortraitWebp: props.page.headingImagePortraitWebp,
			}"
		/>
		<main-content
			:title="props.page.title || props.page.header"
			:subtitle="props.page.subtitle"
			:content="props.page.content"
		/>
	</div>
	<div v-else>
		<slot />
	</div>
</template>

<script setup>
const props = defineProps({
	page: { type: Object, default: () => {} },
});
</script>
